<template>
    <div>


        <b-row class="mt-3">
            <b-col>
                <b-card border-variant="light" class="shadow-sm" header-class="border-0">
                    <template #header>
                        <h6 class="mb-0 details-title-value">
                            {{ $t("resident-agent-permissions.title") }}
                        </h6>
                    </template>
                    <b-card-text>
                        <b-form-checkbox-group class="checkbox-container" v-model="selected">
                            <b-row>
                                <b-col md="3" v-for="role in roles" :key="role.key">
                                    <b-form-checkbox :value="role.key" :disabled="!allowRoles">
                                        <span class="checkbox-text">
                                            {{ role.label }}
                                        </span>
                                        <b-icon-question-circle-fill class="ms-2 card-title-color"
                                            :title="role.details.map(item => `⏵ ${item}`).join(' ')" />
                                    </b-form-checkbox>
                                </b-col>
                            </b-row>
                        </b-form-checkbox-group>
                        <div v-if="allowRoles" class="text-end">
                            <b-overlay :show="isSaving" opacity="0.7" spinner-small spinner-variant="primary"
                                class="d-inline-block">
                                <b-button class="main-btn" @click="savePermissions">
                                    {{ $t("general.save") }}
                                </b-button>
                            </b-overlay>
                        </div>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col>
                <b-card border-variant="light" class="shadow-sm" header-class="border-0">
                    <template #header>
                        <h6 class="mb-0 details-title-value">
                            {{ $t("superintendence-permissions.details") }}
                        </h6>
                    </template>
                    <b-card-text>
                        <b-list-group>
                            <b-list-group-item v-for="permission in permissions" :key="permission" :value="permission">
                                {{ `✅ ${permission}` }}
                            </b-list-group-item>
                        </b-list-group>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { rolePermissions } from "@/config/roles.js";
import { RepositoryFactory } from "@/services/api";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "resident-agent-permissions",
    props: {
        residentAgent: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isSaving: false,
            selected: [],
            permissions: [],
            roles: rolePermissions.filter(x => x.rol === "residentAgent"),
        };
    },
    mounted() {
        this.loadPermissions();
        this.getPermissions();
    },
    computed: {
        ...mapGetters("application", [
            "allowSuperintendenceAsignRoles",
            "allowResidentAgentAsignRoles",
            "isAdmin"
        ]),
        allowRoles() {
            return (
                this.allowSuperintendenceAsignRoles ||
                this.allowResidentAgentAsignRoles ||
                this.isAdmin
            );
        },

    },
    watch: {
        selected() {
            this.getPermissions();
        }
    },
    methods: {
        ...mapActions("modals", ["openModal", "setModalInfo"]),
        async savePermissions() {
            this.isSaving = true;
            try {
                let payload = {
                    id: this.residentAgent.id,
                    permissions: this.selected,
                };
                await RepositoryFactory.residentAgent.updatePermissions(
                    payload
                );
                this.showSuccess();
            } catch {
                this.showError();
            }
            this.isSaving = false;
        },
        showSuccess() {
            this.setModalInfo({
                title: this.$t(`resident-agent-permissions.success.title`),
                message: this.$t(`resident-agent-permissions.success.message`),
                subMessage: this.$t(
                    `resident-agent-permissions.success.subMessage`
                ),
            });
            this.openModal();
        },
        showError() {
            this.setModalInfo({
                title: this.$t(`resident-agent-permissions.error.title`),
                message: this.$t(`resident-agent-permissions.error.message`),
                subMessage: this.$t(
                    `resident-agent-permissions.error.subMessage`
                ),
            });
            this.openModal();
        },
        loadPermissions() {
            let roles = this.residentAgent.roles;
            if (roles?.length > 0) {
                this.selected = roles;
            }
        },
        getPermissions() {
            this.permissions = []
            this.selected.forEach(role => {
                const permission = rolePermissions.filter((q) => q.key === role)
                permission.forEach(item => {
                    item.details.forEach(name => {
                        const index = this.permissions.findIndex((n) => n === name)
                        if (index === -1)
                            this.permissions.push(name)
                    })
                })
            });
        }
    },
};
</script>

<style lang="scss">
.custom-radio {
    display: flex;
    gap: 5px;
    align-items: center;
}
</style>