import { rolePermissions } from "@/config/roles.js";

export function parseFinalBeneficiaryWho(option) {
    switch (option) {
        case "naturalPerson":
            return 0;
        case "pjbolsa":
            return 1;
        case "state":
            return 2;
        case "EEM":
            return 3;
        default:
            return 4;
    }
}

export function parseSocietyType(option) {
    switch (option) {
        case "Sociedad anónima":
            return 'SA';
        case "Sociedad extranjera":
            return 'EX';
        case "Sociedad limitada":
            return 'LI';
        case "SFundación de interés público":
            return 'FP';
        case "Fundación de interés privado":
            return 'FU';
        default:
            return 'S.EP';
    }
}

export function parsePublicRegisterError(code, message = null) {
    switch (code) {
        case 404:
            return "El folio que acaba de introducir no se encuentra registrado, por favor valide el folio.";
        case 400:
            return "El folio que acaba de introducir no se encuentra registrado, por favor valide el folio.";
        case 411:
            return `El folio que acaba de introducir se encuentra ${message}.`;
        case 500:
            return "Ha ocurrido un error al consultar el folio en el registro público. Por favor, valide el folio.";
        case 501:
            return "El folio ingresado ya se encuentra registrado en el sistema.";
        case 502:
            return "El folio ingresado no posee datos en el registro público.";
        case undefined:
            return "El folio que acaba de introducir no es válido.";
        default:
            return "";
    }
}

export const parseRoles = (roles) => {
    let result = "";
    roles.forEach((value) => {
        let role = rolePermissions.filter((q) => q.key === value);

        if (role.length > 0) {
            result += `⏵ ${role[0].label} `;
        }
    });
    return result;
};
